/* GLOBAL SCSS - Use sparingly */
:root {
  --100vh: 100%;
}

html {
  min-height: var(--100vh);
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: var(--100vh);
  height: -webkit-fill-available;
}

body {
  position: relative;
  scroll-behavior: smooth;
  --100vh: 100vh; // Note this is overridden on load via handleAppHeight
  --editor-width: 100vw;
}

// This CSS hides Honeypot input fields. See Honeypot.tsx for more info.
input[name='email-input-0'] {
  position: absolute;
  left: -5000px;
}

// Churnkey Modals (make sure they go on top of Charka modals)
#ck-app {
  .ck-modal-container {
    z-index: 10001 !important;
  }
}
// Should always be under the main cancel modal (#ck-app)
#ck-failed-payment-wall {
  .ck-modal-container {
    z-index: 10000 !important;
  }
}

// Disable all background-attachment: fixed; elements while dragging
// due to chrome bug with scrolling while dragging
// See https://stackoverflow.com/questions/71522204/html5-drag-and-scroll-issue-with-background-attachment-fixed-background-image
body.drag-in-progress {
  * {
    background-attachment: unset !important;
  }
  .block-gridLayout .grid-cell {
    outline-width: var(--grid-border) !important;
  }
  .block-gallery .gallery {
    border-color: var(--grid-outline-color);
  }

  [data-content-reference] {
    // Hack that ensures that the drag preview is cropped according
    // to the transform applied on the child element.
    // See https://github.com/react-dnd/react-dnd/issues/832#issuecomment-442071628
    // (Note this is not a react-dnd dragged element, but the issue still applies)
    transform: translate3d(0, 0, 0);
  }
  .moveable-control {
    // This ensures we hide the crop & resize controls while a drag is in progress
    // so they dont get picked up in the drag preview el.
    display: none !important;
  }
}

// Hide hover elements in editor when formatting menu is open
body.formatting-menu-open {
  .link-preview-hover {
    display: none;
  }
}

.disable-all-transitions * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

// Prevent the floating menu wraper from being interacted with
[data-tippygammatype='floating-menu-popper'] {
  pointer-events: none;
  .tippy-content > * {
    pointer-events: auto;
  }
}

.global-drag-handle {
  position: fixed;
  z-index: 2; // Over card container
  margin: -0.5rem; // Padding around the dragger itself
  margin-right: 0;
  cursor: pointer;
  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 22px;
    font-size: 16px;
    font-weight: 900;
    line-height: 1;
    content: '⋮';
    margin: 0.5rem;
    margin-right: 0;
    background: var(--global-drag-handle-background);
    border: 1px solid var(--global-drag-handle-border);
    color: var(--global-drag-handle-color);
    border-radius: var(--chakra-radii-sm);
    box-shadow: var(--chakra-shadows-sm);
  }
  &[data-isolating-parent='tableCell']::after {
    margin-left: 0;
  }
  &[data-isolating-parent='gridCell']::after {
    margin-left: 0;
  }
  &:hover::after {
    color: var(--global-drag-handle-hover-color);
    background: var(--global-drag-handle-hover-background);
  }
}

.debug-comments .debug-comment-cursor:not(.block) {
  background-color: orange;
}
.debug-comments .block.debug-comment-block {
  outline: 1px solid orange;
}
.block.debug-comment-block .debug-comments {
  outline: 1px solid orange;
}

.annotatable-node-view-wrapper {
  display: flex;
  flex-direction: column;
}

.annotatable-node-view-wrapper.is-mobile {
  flex-direction: column-reverse;
}
.annotatable-node-view-wrapper.is-mobile {
  .block-comments-inner-stack {
    margin-bottom: -0.5rem;

    .block-comment-button-wrapper {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.annotatable-node-view-wrapper:hover .controls-wrapper {
  opacity: 1;
}

[data-hide-comments='true'] {
  .annotatable-node-view-wrapper .comments-wrapper {
    display: none;
    .controls-wrapper {
      display: none;
    }
  }
}

// dont show add comment button when card layout resizing is occuring
// NOTE: this still keeps existing comments showing
.has-column-resize-handle {
  .annotatable-node-view-wrapper .comments-wrapper {
    .controls-wrapper .block-comments-stack.is-empty {
      display: none;
    }
  }
}

.annotatable-activator {
  position: absolute;
  margin-left: 100%;
  top: 0;
  left: 0;
  height: 100%;
  box-sizing: content-box;
  padding-left: 3rem;
  width: 50px;
}

// https://nolanlawson.com/2022/04/08/the-struggle-of-using-native-emoji-on-the-web/
.emoji {
  font-family: 'Twemoji Mozilla', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji', 'EmojiOne Color', 'Android Emoji',
    sans-serif;
  font-weight: 400; // NB: DO NOT REMOVE! Without this, emoji outputted by puppeteer using Noto Color Emoji will show up blank!
  color: black;
}

.ProseMirror .tableWrapper {
  overflow-x: auto;
}
.ProseMirror .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: var(--chakra-colors-trueblue-300);
  // pointer-events: none;
  cursor: ew-resize;
  cursor: col-resize;
  &.left {
    left: -2px;
  }
  &.top,
  &.bottom {
    cursor: ns-resize;
    cursor: row-resize;
    left: 0;
    right: 0;
    width: unset;
    height: 4px;
  }

  &.top {
    top: -2px;
  }

  &.bottom {
    bottom: -2px;
  }
}

.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

body.is-taking-action .block-comments-stack:not(.is-taking-action) {
  pointer-events: none;
}

body.is-taking-action
  .block-comments-stack:not(.is-taking-action)
  .block-comments-inner-stack {
  transform: scale(0.4);
  background-color: transparent;
  box-shadow: none;
  border: 1px solid transparent;
  button {
    opacity: 0;
  }
}
body.is-taking-action
  .block-comments-stack:not(.is-taking-action)
  .avatar-group-popup {
  opacity: 0;
  transform: scale(0.3);
  transform-origin: -50% 50%;
  transition-delay: 50ms;
}

.block-comments-stack {
  opacity: 1;
  pointer-events: auto;
  // zIndex is 5 here, has to below the "sticky" definition for the insert widget
  // but higher than 2 from CardDivider
  z-index: 5;
}

.block-comments-stack.other-comment-open {
  opacity: 0;
  pointer-events: none;
}

// Hack for PPT export
// See packages/client/src/modules/pptx/elements/image.ts
.reset-top-left-margin {
  margin-left: 0 !important;
  margin-top: 0 !important;
  &.reset-max-width-with-margins {
    max-width: unset !important;
  }
}
