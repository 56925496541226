.gamma-switch-container.loading::before {
  background: linear-gradient(
    to bottom,
    #d8ddff 0%,
    rgb(0, 0, 0, 0) 100%
  ) !important;
}
.gamma-switch-container.loading::after {
  background: linear-gradient(
    to top,
    #d8ddff 0%,
    rgb(0, 0, 0, 0) 100%
  ) !important;
}
.gamma-switch-container.active::before {
  background: linear-gradient(to bottom, #ffdede 0%, rgb(0, 0, 0, 0) 100%);
}
.gamma-switch-container.active::after {
  background: linear-gradient(to top, #ffdede 0%, rgb(0, 0, 0, 0) 100%);
}
.gamma-switch-container .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.gamma-switch-container.loading .switch {
  pointer-events: none;
}
.gamma-switch-container.loading .switch .slider {
  width: 24px;
  background-color: #9b999b;
}
.gamma-switch-container.loading .switch .slider::before {
  border: 3px solid #9b999b;
  border-top: 3px solid #6174f0;
  bottom: 0;
  right: 0;
  left: 0;
}
.gamma-switch-container.active .switch .slider::before {
  right: 3px;
  left: unset;
}
.gamma-switch-container.active.loading .switch .slider::before {
  right: 0px;
  left: unset;
}

.switch {
  position: relative;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 36px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: var(--chakra-colors-gray-300);
  transition: all 0.3s ease-in-out;
}
.switch .slider::before {
  content: '';
  position: absolute;
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: rgb(241, 241, 241);
  transition: 0.3s ease;
  transition-property: background-color;
  animation: spin 1s ease infinite;
  box-sizing: content-box;
}
.gamma-switch-container.active .switch .slider.round {
  background-color: var(--chakra-colors-trueblue-500);
}
.switch .slider.round {
  border-radius: 22px;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.1);
}
.switch .slider.round::before {
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
